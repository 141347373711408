export function decodeData(data) {
    if (data instanceof FormData) return data
    if(data instanceof File) return data
    if (isPrimitive(data)) return data // приметивный тип => возвращаем значение
    else if (typeof data === 'object') {
        if (Array.isArray(data)) {
            let newArr = []
            data.forEach(item =>
                newArr.push(decodeData(item)))
            return newArr
        } else {
            const newObject = {}
            Object.keys(data).forEach(key => // проходимся циклом по ключам объекта экранируя имя поля и данные
                newObject[decodeFieldName(key)] = decodeData(data[key]))
            return newObject
        }
    }
}

export function objectCamelToUnderscore(object, ignoreSet) {
    const newObj = {}

    for (let key in object) {
        if (ignoreSet.has(key)) continue

        newObj[camelToUnderscore(key)] = object[key]
    }

    return newObj
}

export function camelToUnderscore(key) {
    return key.replace(/([A-Z])/g, "_$1").toLowerCase();
}

function decodeFieldName(fieldName) {
    const arr = fieldName.split(/(?=[A-Z])/)
    let newFieldName = ''
    arr.forEach((item, index) => { // собираем новый fieldName
        if (index === 0) newFieldName += item
        else newFieldName += '_' + item[0].toLowerCase() + item.slice(1, item.length) // первая буква большая, остальные как есть
    })
    return newFieldName
}

export function encodeData(data) {
    if (isPrimitive(data)) return data // приметивный тип => возвращаем значение
    else if (typeof data === 'object') {
        if (Array.isArray(data)) {
            let newArr = []
            data.forEach(item =>
                newArr.push(encodeData(item)))
            return newArr
        } else {
            const newObject = {}
            Object.keys(data).forEach(key => // проходимся циклом по ключам объекта экранируя имя поля и данные
                newObject[encodeFieldName(key)] = encodeData(data[key]))
            return newObject
        }
    }
}

function encodeFieldName(fieldName) {
    const arr = fieldName.split('_') // разбили на слова
    let newFieldName = ''
    arr.forEach((item, index) => { // собираем новый fieldName
        if (index === 0) newFieldName += item
        else newFieldName += item[0].toUpperCase() + item.slice(1, item.length) // первая буква большая, остальные как есть
    })
    return newFieldName
}

// проверяет является ли поле приметивным типом
function isPrimitive(data) {
    if (!data) return true
    const type = typeof data
    return type === 'string' || type === 'number' || type === 'boolean'
}
