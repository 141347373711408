import Notification from 'element-ui/packages/notification/src/main'

class NotificationsHelper {

    success(message = 'Completed successfully') {
        Notification.success({
            title: 'Успех',
            message: message
        })
    }
    warning(message = 'Completed successfully') {
        Notification.warning({
            title: 'Предупреждение',
            message: message,
        })
    }
    error(message = 'Completed successfully') {
        Notification.error({
            title: 'Ошибка',
            message: message,
        })
    }
    
    /**
     * @param {Object} options
     * @param {Boolean|String|{find: String, set: String}} options.backend
     * @param {Boolean|String} options.base
     * */
    fromHttpError(error, options = null) {
        if (options) options = { base: true, backend: true, ...options }
        else options = { base: true, backend: true }
        
        const backendMessage = this._getBackendErrorMessage(error)
        if (typeof error.code === 'string') {
            Notification.error({title: 'Ошибка', message: 'Проверьте подключение к интернету'})
            console.error(error)
        } else if (options.backend && backendMessage) {
            if (typeof options.backend === 'object' && options.backend.find === backendMessage) {
                Notification.error({title: 'Ошибка', message: options.backend.set})
            } else {
                Notification.error({title: 'Ошибка', message: backendMessage})
            }
        } else if (options.base) {
            if (typeof options.base === 'string') {
                Notification.error({title: 'Ошибка', message: options.base})
            } else {
                Notification.error({title: 'Ошибка', message: 'Что-то пошло не так'})
            }
        }
    }
    
    _getBackendErrorMessage(error) {
        if (error.response?.data?.reasons?.[0]) {
            return error.response.data.reasons[0]
        } else if(error.response?.data?.message) {
            return error.response.data.message
        } else if(error.response?.data?.errors?.[0]?.message) {
            return error.response.data.errors[0].message
        } else return null
    }
    
}

export const notificationsHelper = new NotificationsHelper()
